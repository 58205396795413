import React from 'react'
import { useTable } from '@components/Table/hooks'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import { getInterviewFeedbacks } from '@src/api/recruitment/interviews'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  interviewCandidate,
  interviewDateColumn,
  interviewNameColumn,
  interviewRatingColumn,
  interviewRoleSeniority,
  interviewScorecardColumn,
  interviewStatus,
} from '@src/constants/columns/interviews'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { MoreBar, TableWidget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { InterviewStats } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/InterviewStats'
import { StagesBankAction } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/Actions/StagesBankAction'
import { ManageInterviewingScorecardsAction } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/Actions/ManageInterviewingScorecardsAction'
import { RecruitmentGroupsAction } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/Actions/RecruitmentGroupsAction'

const rows: RowInterface<InterviewFeedbackInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, {
        id: data.id,
      }),
    ),
  cells: [
    {
      ...interviewStatus,
      width: 140,
    },
    {
      ...interviewCandidate,
      width: 290,
    },
    {
      ...interviewRoleSeniority,
      width: 300,
    },
    {
      ...interviewNameColumn,
      width: 160,
    },
    {
      ...interviewDateColumn,
      width: 220,
    },
    {
      ...interviewRatingColumn,
      width: 120,
    },
    {
      ...interviewScorecardColumn,
      width: 150,
    },
  ],
}

export const Interviews = () => {
  const table = useTable<InterviewFeedbackInterface>(
    { getItems: getInterviewFeedbacks },
    undefined,
    [
      {
        sortBy: 'status_order',
        direction: SORT_DIRECTION.DESC,
      },
      {
        sortBy: 'scheduled_date_time',
        direction: SORT_DIRECTION.ASC,
      },
    ],
  )

  return (
    <TableWidget>
      <TableWidget.Info>
        <InterviewStats filters={table.filterBy} onFilterChange={table.onFilterChange} />
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          <StagesBankAction />
          <ManageInterviewingScorecardsAction />
          <RecruitmentGroupsAction />
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable<InterviewFeedbackInterface>
          name={TableNames.Interviews}
          useWindowScroll
          dataType="Interviews"
          row={rows}
          {...table}
          noDataMessage="Interviews will appear here."
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
