import React, { useState } from 'react'
import { ROUTES } from '@src/constants/routes'
import {
  Avatar,
  Box,
  Color,
  Group,
  Item,
  Subheader,
  VStack,
  Text,
  ItemSkeleton,
  ActionButton,
  Cell,
  HStack,
  Popup,
  Header,
  Button,
  Token,
  IconName,
} from '@revolut/ui-kit'
import { BrokenHeart, Card } from '@revolut/icons'
import { PageBody } from '@components/Page/PageBody'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { FeatureFlags, GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { APPLICATIONS } from '@src/constants/hub'
import { getIconProps } from '@src/pages/Hub/utils'
import {
  companyPreferencesItemConfig,
  securitySettingsItemConfig,
} from '../common/common'
import { GlobalSettingsRecord, useGlobalSettings } from '@src/api/settings'
import {
  resetSubscriptions,
  restrictSubscription,
  useSubsciptionInfo,
} from '@src/api/plans'
import { UseSubsciptionInfoResult } from '@src/interfaces/plans'
import { useQueryClient } from 'react-query'
import {
  CardItemAvatar,
  FAILED_PAYMENT_MESSAGE,
  NO_PAYMENT_METHOD_MESSAGE,
} from '@src/pages/Forms/Plans/common'
import { API } from '@src/constants/api'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { navigateTo } from '@src/actions/RouterActions'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'

type SettingsRouteConfigBase = {
  path: string
  title: string
  description?: React.ReactNode
  canView: PermissionTypes[]
  globalSetting?: GlobalSettings
}

export const generalSettings = (options: {
  isCommercial: boolean
  accountsEnabled?: boolean
}) =>
  [
    ...(options.accountsEnabled
      ? [
          {
            path: ROUTES.SETTINGS.ACCOUNTS.LIST,
            title: 'Accounts',
            description: 'Manage client account details',
            icon: 'Services' as const,
            color: Token.color.blue,
            bg: Token.color.background,
            canView: [PermissionTypes.ViewCompanySettings],
          },
          {
            path: ROUTES.SETTINGS.PLAN_MANAGEMENT.LIST,
            title: 'Plan management',
            description: 'Manage Pricing Plans',
            icon: 'StarFilled' as const,
            color: Token.color.blue,
            bg: Token.color.background,
            canView: [PermissionTypes.ViewCompanySettings],
          },
        ]
      : []),
    companyPreferencesItemConfig,
    securitySettingsItemConfig,
    {
      ...APPLICATIONS.locations,
      color: Token.color.blue,
      bg: Token.color.background,
    },
    {
      ...APPLICATIONS.entities,
      color: Token.color.blue,
      bg: Token.color.background,
    },
    {
      ...APPLICATIONS.communication,
      color: Token.color.blue,
      bg: Token.color.background,
      path: APPLICATIONS.communication.tabs[0].path,
      canView: APPLICATIONS.communication.tabs[0].canView,
    },
    ...(!options.isCommercial
      ? [
          {
            ...APPLICATIONS.feedback,
            bg: Token.color.background,
            color: Token.color.blue,
          },
        ]
      : []),
    {
      ...APPLICATIONS.accessManagement,
      bg: Token.color.background,
      canView: APPLICATIONS.accessManagement.tabs[0].canView,
      path: APPLICATIONS.accessManagement.tabs[0].path,
      color: Token.color.blue,
    },
    {
      ...APPLICATIONS.values,
      color: Token.color.blue,
    },
    ...(!options.isCommercial
      ? [
          {
            ...APPLICATIONS.helpCenter,
            color: Token.color.blue,
            bg: Token.color.background,
            path: APPLICATIONS.helpCenter.tabs[0].path,
            canView: APPLICATIONS.helpCenter.tabs[0].canView,
          },
        ]
      : []),
  ].filter(Boolean)

export const plansAndBilling = (
  settings: UseSubsciptionInfoResult,
): SectionSettings[] => {
  if (settings.enabled) {
    if (settings.info?.subscription_plan) {
      const noPaymentMethod = settings.info?.revolut_merchant_api_payment_method === null
      const lastPaymentFailed = settings.info?.last_payment_status?.id === 'failure'

      return [
        {
          path: ROUTES.PLANS.SELECT_PLAN,
          title: settings.info?.subscription_plan?.name,
          description: 'Billed monthly per active employee',
          icon: 'StarFilled',
          color: Token.color.blue,
          bg: Token.color.background,
          canView: [PermissionTypes.ManageSubscriptionPlans],
        },
        {
          path: ROUTES.PLANS.PAYMENT_DETAILS,
          title: 'Subscription And Payments',
          description: (
            <VStack space="s-8">
              <Text>Manage subscription plans, payment details and invoices</Text>
              {noPaymentMethod ? (
                <Text color={Token.color.red}>{NO_PAYMENT_METHOD_MESSAGE}</Text>
              ) : null}
              {lastPaymentFailed ? (
                <Text color={Token.color.red}>{FAILED_PAYMENT_MESSAGE}</Text>
              ) : null}
            </VStack>
          ),
          customIcon: (
            <CardItemAvatar
              variant={noPaymentMethod || lastPaymentFailed ? 'error' : 'default'}
              type="card"
            />
          ),
          color: Token.color.blue,
          bg: Token.color.background,
          canView: [PermissionTypes.ManageSubscriptionPlans],
        },
      ]
    }

    return [
      {
        path: ROUTES.PLANS.SELECT_PLAN,
        title: 'Select a plan',
        description: (
          <Text color={Color.ORANGE}>
            Select a plan that fits your organisation and switch to it after trial period.
          </Text>
        ),
        customIcon: <CardItemAvatar variant="warning" type="card" />,
        bg: APPLICATIONS.employees.bg,
        canView: [PermissionTypes.ManageSubscriptionPlans],
      },
    ]
  }

  return []
}

export const peopleSettings = (): SectionSettings[] =>
  [
    {
      ...settingsConfig.employees,
      icon: APPLICATIONS.employees.icon,
      image: APPLICATIONS.employees.image,
      bg: APPLICATIONS.employees.bg,
    },
    {
      ...settingsConfig.timeOff,
      icon: APPLICATIONS.timeOff.icon,
      image: APPLICATIONS.timeOff.image,
      bg: APPLICATIONS.timeOff.bg,
    },
    {
      ...settingsConfig.documents,
      icon: APPLICATIONS.documents.icon,
      image: APPLICATIONS.documents.image,
      bg: APPLICATIONS.documents.bg,
    },
    {
      ...settingsConfig.payroll,
      icon: APPLICATIONS.payroll.icon,
      image: APPLICATIONS.payroll.image,
      bg: APPLICATIONS.payroll.bg,
    },
    {
      ...APPLICATIONS.groups,
    },
  ].filter(Boolean)

export const recruitmentSettings = (settings: GlobalSettingsRecord) =>
  [
    settings.requisitions_enabled || settings.job_postings_enabled
      ? { ...settingsConfig.jobs, icon: APPLICATIONS.jobs.icon, bg: APPLICATIONS.jobs.bg }
      : null,
    settings.candidates_enabled
      ? {
          ...settingsConfig.candidates,
          icon: APPLICATIONS.candidates.icon,
          image: APPLICATIONS.candidates.image,
          bg: APPLICATIONS.candidates.bg,
        }
      : null,
  ].filter(Boolean)

export const organisationSettings = [
  {
    ...settingsConfig.teams,
    image: APPLICATIONS.teams.image,
    bg: APPLICATIONS.teams.bg,
    icon: APPLICATIONS.teams.icon,
  },
  {
    ...settingsConfig.roles,
    image: APPLICATIONS.positions.image,
    icon: APPLICATIONS.positions.icon,
    bg: APPLICATIONS.positions.bg,
  },
]

export const performanceSettings = [
  {
    ...settingsConfig.kpis,
    title: APPLICATIONS.kpis.title,
    image: APPLICATIONS.kpis.image,
    bg: APPLICATIONS.kpis.bg,
    icon: APPLICATIONS.kpis.icon,
  },
  {
    ...settingsConfig.performance,
    title: APPLICATIONS.performanceReview.title,
    icon: APPLICATIONS.performanceReview.icon,
    image: APPLICATIONS.performanceReview.image,
    bg: APPLICATIONS.performanceReview.bg,
  },
  {
    ...APPLICATIONS.reportingApp,
    canView: [],
    path: ROUTES.SETTINGS.ANALYTICS,
  },
  {
    ...settingsConfig.engagement,
    icon: APPLICATIONS.engagement.icon,
    image: APPLICATIONS.engagement.image,
    bg: APPLICATIONS.engagement.bg,
  },
]

interface SectionSettings {
  path: string
  title: string
  canView: PermissionTypes[]
  description?: React.ReactNode
  icon?: IconName
  color?: string
  customIcon?: React.ReactNode
  image?: string
  bg?: string
}

interface SettingsSectionProps {
  title: string
  settings: SectionSettings[]
}

export const settingsFilter =
  (permissions?: PermissionTypes[]) => (setting: SettingsRouteConfigBase) =>
    setting.canView.filter(permission => permissions?.includes(permission)).length ===
    setting.canView.length

const SettingsSection = ({ title, settings }: SettingsSectionProps) => {
  const permissions = useSelector(selectPermissions)

  const filteredSettings = settings.filter(settingsFilter(permissions))

  if (!filteredSettings.length) {
    return null
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>{title}</Subheader.Title>
      </Subheader>
      <Group>
        {filteredSettings.map(setting => (
          <Item
            variant="disclosure"
            type="button"
            use={InternalLink}
            to={pathToUrl(setting.path)}
            key={setting.path}
          >
            <Item.Avatar>
              {setting.customIcon ? setting.customIcon : null}
              {setting.icon ? (
                <Avatar
                  useIcon={setting.icon}
                  bg={setting.bg}
                  color={setting.color || Token.color.white}
                />
              ) : setting.image && setting.bg ? (
                <Avatar
                  variant="app"
                  {...getIconProps(setting.image, setting.bg, 40)}
                  size={40}
                />
              ) : null}
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{setting.title}</Item.Title>
              {setting.description ? (
                <Item.Description>{setting.description}</Item.Description>
              ) : null}
            </Item.Content>
          </Item>
        ))}
      </Group>
    </Box>
  )
}

const PlansAndBillinSettingsSection = () => {
  const subscriptionInfo = useSubsciptionInfo()

  const title = `Plans & Billing`

  if (!subscriptionInfo.enabled || subscriptionInfo.isError) {
    return null
  }

  if (subscriptionInfo.enabled && !subscriptionInfo.info) {
    return (
      <Box>
        <Subheader variant="nested">
          <Subheader.Title>{title}</Subheader.Title>
        </Subheader>
        <ItemSkeleton />
      </Box>
    )
  }

  return (
    <>
      <SettingsSection title={title} settings={plansAndBilling(subscriptionInfo)} />
      <ResetSubscriptionPlanButton />
    </>
  )
}

/** Only for development and testing */
const ResetSubscriptionPlanButton = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const subscriptionInfo = useSubsciptionInfo()

  const queryClient = useQueryClient()

  const [resetSubscriptionPending, setResetSubscriptionPending] = useState(false)
  const [restrictAccountPending, setRestrictAccountPending] = useState(false)

  const subscriptionPlansDevtoolsEnabled = featureFlags.includes(
    FeatureFlags.SubscriptionPlansDevtools,
  )

  const onResetSubscriptionPlan = () => {
    setResetSubscriptionPending(true)

    resetSubscriptions()
      .then(() => queryClient.invalidateQueries(API.SUBSCRIPTIONS_INFO_API))
      .finally(() => setResetSubscriptionPending(false))
  }

  const onRestrictAccount = () => {
    setRestrictAccountPending(true)

    restrictSubscription()
      .then(() => {
        window.location.reload()
      })
      .finally(() => setRestrictAccountPending(false))
  }

  if (!subscriptionPlansDevtoolsEnabled || !subscriptionInfo.info?.subscription_plan) {
    return null
  }

  return (
    <HStack space="s-8">
      <ActionButton onClick={onResetSubscriptionPlan} pending={resetSubscriptionPending}>
        Reset subscription plan
      </ActionButton>
      <ActionButton onClick={onRestrictAccount} pending={restrictAccountPending}>
        Restrict account
      </ActionButton>
    </HStack>
  )
}

const CloseAccountSection = () => {
  const subscriptionInfo = useSubsciptionInfo()
  const permissions = useSelector(selectPermissions)

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [isCantCloseAccountOpen, setIsCantCloseAccountOpen] = useState(false)

  const canCloseAccount = permissions.includes(PermissionTypes.CloseAccount)

  if (!subscriptionInfo.enabled || !subscriptionInfo.info || !canCloseAccount) {
    return null
  }

  const onCloseAccountClick = () => {
    if (subscriptionInfo.info?.state.id === 'closing') {
      navigateTo(ROUTES.SETTINGS.CLOSE_ACCOUNT.SCHEDULED_FOR_CLOSURE)
    } else {
      setIsConfirmationOpen(true)
    }
  }

  const onConfirmCloseAccount = () => {
    if (subscriptionInfo.info?.last_payment_status?.id === 'failure') {
      setIsConfirmationOpen(false)
      setIsCantCloseAccountOpen(true)
    } else {
      navigateTo(ROUTES.SETTINGS.CLOSE_ACCOUNT.SURVEY)
    }
  }

  return (
    <>
      <Box>
        <Subheader variant="nested">
          <Subheader.Title>Close Account</Subheader.Title>
        </Subheader>
        <Cell variant="disclosure" use="button" onClick={onCloseAccountClick}>
          <HStack align="center" space="s-16">
            <BrokenHeart color={Color.RED} />
            <Text variant="primary">Close Revolut People account</Text>
          </HStack>
        </Cell>
      </Box>

      <ConfirmationDialog
        label="Are you sure you want to close Revolut People account?"
        body="After grace period all your data will be removed and you will not be able to use Revolut People."
        open={isConfirmationOpen}
        onClose={() => {
          setIsConfirmationOpen(false)
        }}
        onReject={() => {
          setIsConfirmationOpen(false)
        }}
        onConfirm={onConfirmCloseAccount}
        yesMessage="Confirm"
        noMessage="Cancel"
        variant="compact"
      />

      <Popup
        open={isCantCloseAccountOpen}
        onClose={() => setIsCantCloseAccountOpen(false)}
        variant="modal-view"
      >
        <Header>
          <Header.CloseButton aria-label="Close" />
          <Header.Title>We can't close your account yet</Header.Title>
        </Header>

        <Text mb="s-16">
          You need to complete the following actions for us to close your account
        </Text>

        <Item use={InternalLink} variant="disclosure" to={ROUTES.PLANS.PAYMENT_DETAILS}>
          <Item.Avatar>
            <Avatar useIcon={Card} />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Subscription And Payments</Item.Title>
            <Item.Description>
              Standard plan payment was declined. Please check payment details.
            </Item.Description>
          </Item.Content>
        </Item>

        <Popup.Actions horizontal>
          <Button onClick={() => setIsCantCloseAccountOpen(false)} elevated>
            Keep account open
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

const SettingsLandingPage = () => {
  useSetDocumentTitle('Settings')

  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const { settings } = useGlobalSettings()

  return (
    <PageWrapper>
      <PageHeader title="Settings" backUrl={ROUTES.PREFERENCES.ALL} />
      <PageBody>
        <VStack space="s-16">
          <SettingsSection
            title="General"
            settings={generalSettings({
              isCommercial,
              accountsEnabled: featureFlags.includes(FeatureFlags.TenantAccounts),
            })}
          />
          <PlansAndBillinSettingsSection />
          <SettingsSection title="People" settings={peopleSettings()} />
          <SettingsSection title="Recruitment" settings={recruitmentSettings(settings)} />
          <SettingsSection title="Organisation" settings={organisationSettings} />
          <SettingsSection title="Performance" settings={performanceSettings} />
          <CloseAccountSection />
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}

export default SettingsLandingPage
